import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/index.vue'
import AiModule from './modules/ai'
import ActivityModule from './modules/activity'
import Drug from './modules/drug'
// 个人中心路由的动态重定向
// const redire = localStorage.getItem('path') || ''
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/authorize',
    component: () => import('@/views/authorize/index.vue')
  },
  // 个人中心
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('@/views/userInfo/index.vue'),
    redirect: '/userInfo/read',
    children: [
      // 个人信息
      // { path: 'user', component: () => import('@/views/userInfo/components/user.vue') },
      // 最近阅读
      {
        path: 'read',
        component: () => import('@/views/userInfo/components/read.vue'),
        meta: { title: '最近阅读', icon: 'icon-zuijinyuedu' }
      },
      // 用户收藏
      {
        path: 'collect',
        component: () => import('@/views/userInfo/components/collect.vue'),
        meta: { title: '我的收藏', icon: 'icon-wodeshoucang' }
      },
      // 下载记录
      {
        path: 'download',
        component: () => import('@/views/userInfo/components/download.vue'),
        meta: { title: '下载记录', icon: 'icon-xiazaijilu' }
      },
      // 我的笔记
      {
        path: 'myNote',
        component: () => import('@/views/userInfo/components/myNote.vue'),
        meta: { title: '我的笔记', icon: 'icon-jilu' }
      }
    ]
  },
  // 文献详情
  {
    path: '/articleDetails',
    name: 'articleDetails',
    component: () => import('@/views/articleDetails/index.vue')
  },
  // 文献pdf展示
  {
    path: '/pdfDetails',
    name: 'pdfDetails',
    component: () => import('@/views/pdfDetails/index.vue')
  },
  // 搜索文章列表
  {
    path: '/searchList',
    name: 'searchList',
    component: () => import('@/views/searchList/index.vue')
  },
  // 资讯列表
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index.vue')
  },
  // 资讯详情
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('@/views/newsDetails/index.vue')
  },
  // 关于我们
  {
    path: '/aboutUs',
    name: 'aboutUs',
    redirect: '/aboutUs/aboutCompany',
    component: () => import('@/views/aboutUs/index.vue'),
    children: [
      // 公司简介
      { path: 'aboutCompany', component: () => import('@/views/aboutUs/components/company.vue') },
      // 产品介绍
      { path: 'aboutProduct', component: () => import('@/views/aboutUs/components/product.vue') }
    ]
  },
  // 笔记详情
  {
    path: '/noteDetails',
    name: 'noteDetails',
    component: () => import('@/views/noteDetails/index.vue')
  },
  // 指南列表页
  {
    path: '/guideline',
    name: 'guideline',
    component: () => import('@/views/guideline/index.vue'),
    meta: { keepAlive: true }
  },
  // 期刊列表页
  {
    path: '/periodical',
    name: 'Periodical',
    component: () => import('@/views/periodical/index.vue'),
    meta: { keepAlive: true }
  },
  // 期刊详情页
  {
    path: '/JournalDetails',
    name: 'JournalDetails',
    component: () => import('@/views/JournalDetails/index.vue')
  },
  // 文献王
  {
    path: '/literatureKing',
    name: 'literatureKing',
    component: () => import('@/views/literatureKing/index.vue')
  },
  // info学院
  {
    path: '/infoCollege',
    name: 'infoCollege',
    component: () => import('@/views/infoCollege/index.vue'),
    meta: { keepAlive: true }
  },
  // 学院视频详情页
  {
    path: '/videoDetails',
    name: 'videoDetails',
    component: () => import('@/views/infoCollegeVideoDetails/index.vue')
  },
  // 学院精选资料详情页
  {
    path: '/documentDetails',
    name: 'documentDetails',
    component: () => import('@/views/infoCollegeDocumentDetails/index.vue')
  },
  // 国自然搜索模块
  {
    path: '/guoZiRan',
    name: 'guoZiRan',
    component: () => import('@/views/guoZiRan/index.vue')
  },
  // 国自然相关论文列表页
  {
    path: '/gzrArticleList',
    name: 'gzrArticleList',
    component: () => import('@/views/gzrArticleList/index.vue')
  },
  // 国自然pdf查看页
  {
    path: '/gzrPdfPage',
    name: 'gzrPdfPage',
    component: () => import('@/views/gzrPdfPage/index.vue')
  },
  // 视频上传页
  {
    path: '/uploadVideo',
    name: 'uploadVideo',
    component: () => import('@/views/uploadVideo/index.vue'),
    meta: { keepAlive: true }
  },
  // 高级搜索页
  {
    path: '/advanceSearch',
    name: 'advanceSearch',
    component: () => import('@/views/advanceSearch/index.vue'),
    meta: { keepAlive: true }
  },
  // 高级搜索结果页
  {
    path: '/advanceSearchDocList',
    name: 'advanceSearchDocList',
    component: () => import('@/views/advanceSearchDocList/index.vue')
  },
  // 反馈中心
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('@/views/feedback/index.vue')
  },
  // 通知中心
  {
    path: '/msgContent',
    component: () => import('@/views/msgContent/index.vue')
  }, {
    path: '/guideDetails',
    component: () => import('@/views/guideDetails/index.vue')
  }, {
    path: '/pptView',
    component: () => import('@/views/pptView/index.vue')
  },
  // 404页面
  {
    path: '/404',
    component: () => import('@/views/404/index.vue')
  },
  ...AiModule,
  ...ActivityModule,
  ...Drug,
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes,
  // 页面跳转让滚动条回到顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

// 生成网站地图
// function getRoutesList (routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`

//     if (route.path !== '*') {
//       array.push(path)
//     }

//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`))
//     }

//     return array
//   }, [])
// }

// // getRoutesList(router.options.routes, 'https://zigamiklic.com');
// function getRoutesXML () {
//   console.log(getRoutesList(router.options.routes, 'https://www.infox-med.com/#'))
//   const list = getRoutesList(router.options.routes, 'https://www.infox-med.com/#')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n')
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`
// }

// console.log(getRoutesXML())
export default router
