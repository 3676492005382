export default {
  namespaced: true,
  state: () => ({
    aiLinkLoading: false
  }),
  mutations: {
    // 设置链接状态loading
    setLinkLoading (state, type) {
      state.aiLinkLoading = type
    }
  }
}
